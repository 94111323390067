import React from 'react';
import { Header, Grid, Button, Dropdown } from 'semantic-ui-react';
import { graphql, navigate, Link } from "gatsby";
import Magnifier from "react-magnifier";
import Helmet from 'react-helmet';

import Layout from "../components/layout";
import CardContext from '../components/CartContext';
import '../style/art-page.css';

export default class extends React.Component {
  constructor(props) {
    super(props);

    const { airtable } = this.props.data;
    const { data: artPageData } = airtable;
    const sizeOptions = artPageData.Print_Sizes && artPageData.Print_Prices ?
      artPageData.Print_Sizes.map((size, i) => ({
        key: size,
        value: `${size} - $${artPageData.Print_Prices[i]}`,
        text: `${size} - $${artPageData.Print_Prices[i]}`
      })) : [];

    this.state = {
      loading: false,
      sizeOptions,
      sizeChoice: sizeOptions.length > 0 && sizeOptions[0].value,
    };

    this.onSizeChange = this.onSizeChange.bind(this);
    this.createOrder = this.createOrder.bind(this);
  }

  createOrder() {
    const { sizeChoice } = this.state;
    const { airtable } = this.props.data;
    const { data: artPageData } = airtable;
    const sizeChoiceTokens = sizeChoice.split(" - ");
    const [size, price] = sizeChoiceTokens;
    const { Title, Description } = artPageData;
    const redirectUrl = window.location.href;

    return {
      price: parseInt(price.replace("$", "").trim()) * 100,
      name: `${Title} - ${size}`,
      description: Description,
      redirectUrl: redirectUrl
    }
  }

  onSizeChange(e, { value }) {
    this.setState({ sizeChoice: value })
  }

  isInCart(cartItems) {
    const { airtable } = this.props.data;
    const { data: artPageData } = airtable;
    const { Title } = artPageData;

    return cartItems.filter(({ name }) => name.includes(Title)).length !== 0;
  }

  formatDimensions(value) {
    if (!value) {
      return ""
    }

    if (value.includes('x')) {
      return value.split('x').map(dim => `${dim}"`).join('x');
    } else {
      return `${value}"`;
    }
  }


  render() {
    const { airtable } = this.props.data;
    const { loading } = this.state;
    const { data: artPageData } = airtable;

    return (
      <Layout>
        <Helmet
          title={`${artPageData.Title}, Original Piece of Contemporary Art by Ari Hauben`}
          meta={[
            { name: 'description', content: artPageData.Description },
          ]}
        />
        <Grid columns={2} stackable>
          <Grid.Column>
            <Magnifier src={artPageData.ImageURL} width="100%" mgShape="square" mgWidth={200} mgHeight={200} />
          </Grid.Column>
          <Grid.Column>
            <Header as="h1">{artPageData.Title.toUpperCase()}</Header>
            <p className="art-page-header-info">
              {artPageData.Original_Status === "sold" && "Original: SOLD"}
              {artPageData.Original_Status === "available" && (
                <>
                  Original Available - {' '}<a href="mailto:arihaubenart@gmail.com">
                  Contact Ari
                </a>{' for Pricing'}
                </>
              )}
            </p>
            {/*{artPageData.Print_Prices && (*/}
              {/*<React.Fragment>*/}
                {/*<p className="art-page-header-info">Price: From ${artPageData.Print_Prices[0].toUpperCase()}</p>*/}
                {/*<p className="art-page-header-info-sec">Product Options:</p>*/}
                {/*<span className="art-page-header-info-sec">Size To Price: </span>*/}
                {/*<Dropdown*/}
                {/*className="art-page-size-sel"*/}
                {/*selection*/}
                {/*options={this.state.sizeOptions}*/}
                {/*placeholder="Size"*/}
                {/*value={this.state.sizeChoice}*/}
                {/*onChange={this.onSizeChange}*/}
                {/*text={this.state.sizeChoice}*/}
                {/*/>*/}
              {/*</React.Fragment>*/}
            {/*)}*/}
            {/*<CardContext.Consumer>*/}
              {/*{*/}
                {/*({ onAddCartItem, cartItems }) => artPageData.Print_Prices && (*/}
                  {/*<Button*/}
                    {/*onClick={this.isInCart(cartItems)*/}
                      {/*? () => navigate('cart')*/}
                      {/*: () => onAddCartItem(this.createOrder())*/}
                    {/*}*/}
                    {/*className="art-page-purchase-button"*/}
                    {/*fluid*/}
                    {/*loading={loading}*/}
                  {/*>*/}
                    {/*{this.isInCart(cartItems) ? "See Cart" : "Add To Cart"}*/}
                  {/*</Button>*/}
                {/*)*/}
              {/*}*/}
            {/*</CardContext.Consumer>*/}
            <div className="art-page-metadata">
              {artPageData.Description && <p className="art-page-metadata-title">Description of Original</p>}
              <p>{artPageData.Description}</p>
              {artPageData.Size && <p className="art-page-metadata-title">Original Size</p>}
              <p>{this.formatDimensions(artPageData.Size)}</p>
              <p className="art-page-metadata-title">Reproductions of Original</p>
              <p>
                A variety of limited edition reproductions, one of a kind hand finished prints and unique digital styles are available for purchase. Each piece is produced using only the highest quality archival materials.
              </p>
              <ul>
                <li>Limited edition giclee prints on archival paper.</li>
                <li>Stretched canvas prints</li>
                <li>Dye sublimation onto metal</li>
              </ul>
              <p>For pricing, sizing and framing options please contact Ari{' '}<a href="mailto:arihaubenart@gmail.com">here</a>.</p>
              {artPageData.About_Ari && <p className="art-page-metadata-title">About Ari</p>}
              <p>{artPageData.About_Ari}</p>
              {/*{artPageData.Additional_Options && (*/}
                {/*<React.Fragment>*/}
                  {/*<p className="art-page-metadata-title">Additional Options</p>*/}
                  {/*<ul style={{ marginTop: '0.25rem', paddingInlineStart: 20 }}>*/}
                    {/*{artPageData.Additional_Options*/}
                      {/*.slice(1)*/}
                      {/*.split(' -')*/}
                      {/*.map(option => <li>{option.trim()}</li>)*/}
                    {/*}*/}
                  {/*</ul>*/}
                {/*</React.Fragment>*/}
              {/*)}*/}
            </div>
          </Grid.Column>
        </Grid>
      </Layout>
    )
  }
};

export const query = graphql`
  query($title: String!) {
    airtable(table: {eq: "Artwork"}, data: {Title: {eq: $title}}) {
      data {
        Title
        Image {
          url
        }
        ImageURL
        Size
        Type
        Description
        Original_Status
        Print_Sizes
        Print_Prices
        Reproductions
        About_Ari
        Additional_Options
      }
    }
  }
`;